import React, {useEffect, useState} from 'react';
import BirthplaceMap from './BirthplaceMap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FaChevronDown, FaChevronRight } from 'react-icons/fa'; // Importing icons

const UserForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        dob: '',
        tob: '', // Time of Birth will be manually entered in 12-hour format
        birthplace: null,
        birthplaceName: '',
        advancedOptions: {
            moonTransits: true,
            moonAspects:true,
            moonAspectHouseRulers: false,
            aspectHouseRulers: true,
            outerPlanets:true
        },
        orderId:''
    });

    const [advancedUnderstanding, setAdvancedUnderstanding] = useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false); // State for confirmation stage
    const navigate = useNavigate();
    const defaultAdvancedOptions = {
        moonTransits: true,
        moonAspects: true,
        moonAspectHouseRulers: false,
        aspectHouseRulers: true,
        outerPlanets:true
    };
    const [searchParams] = useSearchParams();
    const order_id=searchParams.get("order_id")
    useEffect(() => {
        if (order_id) {
            setFormData((prevData) => ({
                ...prevData,
                orderId: order_id
            }));
        }
    }, [order_id]);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            advancedOptions: {
                ...formData.advancedOptions,
                [e.target.name]: e.target.checked,
            },
        });
    };

    const handleAdvancedUnderstandingChange = (e) => {
        const isChecked = e.target.checked;
        setAdvancedUnderstanding(isChecked);

        if (!isChecked) {
            setFormData({
                ...formData,
                advancedOptions: defaultAdvancedOptions,
            });
        }
    };

    const handleLocationSelect = (latlng, name) => {
        setFormData({ ...formData, birthplace: latlng, birthplaceName: name });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!formData.birthplace){
            alert("Please select a location by choosing from the suggestions or choosing directly from the map!")
            return;
        }
        setIsConfirming(true)
    };
    const handleBack = () => {
        setIsConfirming(false);
    };
    const handleFinalSubmit = async ()=>{
        setFormSubmitted(true)
        console.log(formData)
        try {
            const response= await fetch('https://af03-users-gp-prod-jp.azurewebsites.net/api/users_function', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok){
                navigate('/thank-you');
            }
            else {
                throw new Error('Something went wrong. Please try again later.');
            }
        }
        catch (error){
            navigate('/error')
        }
    }
    const formatTime = (tob) => {
        const [hours, minutes] = tob.split(':');
        const date = new Date();
        date.setHours(parseInt(hours), parseInt(minutes));
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };
    const formatDate = (dob) => {
        const parts = dob.split('-');
        const utcDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
        return utcDate.toLocaleDateString('en-US', {
            timeZone: "UTC",
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
    return (
        <div className="max-w-md mx-auto p-4 space-y-4">
            {!formSubmitted && !isConfirming ? (
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div>
                    <h1 className="text-4xl text-center font-extrabold text-indigo-600 py-2 px-3 mt-1">The Galactic Planner</h1>
                    <h3 className="text-sm text-justify font-extrabold italic py-2 mt-1">Thank you for purchasing the Galactic Planner! As a final step, please complete your birth information below, so we can create and send you your personalized calendar. Once you click submit, you will receive your Galactic Planner via email within 10 minutes.</h3>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Your Name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Your Email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="dob" className="block text-sm font-medium text-gray-700">Date of Birth</label>
                    <input
                        type="date"
                        name="dob"
                        id="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                        min="1900-01-01"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="tob" className="block text-sm font-medium text-gray-700">Time of Birth</label>
                    <div className="text-xs italic my-2 text-red-500">For accurate calculations please make sure the time and date of birth are accurate within a 15-minute margin.</div>
                    <input
                        type="time"
                        name="tob"
                        id="tob"
                        value={formData.tob}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        required
                    />
                    <div className="text-xs italic my-2 text-red-500">Please note that we do account for Daylight Savings Time and other timezone changes when relevant.</div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">City of Birth</label>
                    <div className="text-xs italic my-2 text-red-500">Please make sure you select your correct city of birth by searching for it or selecting it directly on the map. if you can't find your city you can choose the closest big city to your city of birth.</div>
                    <BirthplaceMap onLocationSelect={handleLocationSelect} initialLocation={formData.birthplace}
                                   initialLocationName={formData.birthplaceName} />
                </div>
                <div>
                    <div className="flex items-center cursor-pointer" onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                        {showAdvancedOptions ? <FaChevronDown /> : <FaChevronRight />}
                        <span className="ml-2 text-sm font-medium text-gray-700">Advanced Options</span>
                    </div>
                    {showAdvancedOptions && (
                        <div className="mt-2 pl-4">
                            <div className="text-xs italic my-2 text-red-500">These options are meant for people with a deep understanding of Vedic Astrology. If you don't have an advanced understanding of Vedic Astrology we got it figured out for you please use the default options.</div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="advancedUnderstanding"
                                    checked={advancedUnderstanding}
                                    onChange={handleAdvancedUnderstandingChange}
                                    className="mr-2"
                                />
                                <label htmlFor="advancedUnderstanding" className="text-sm">I confirm that I have an advanced understanding of vedic astrology and I wish to change the default options.</label>
                            </div>
                            <div className="mt-2">
                                <div>
                                    <input
                                        type="checkbox"
                                        id="moonTransits"
                                        name="moonTransits"
                                        checked={formData.advancedOptions.moonTransits}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                        disabled={!advancedUnderstanding}
                                    />
                                    <label htmlFor="moonTransits" className={`text-sm ${!advancedUnderstanding ? 'text-gray-500' : ''}`}>Show when the Transiting Moon changes houses.</label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="moonAspects"
                                        name="moonAspects"
                                        checked={formData.advancedOptions.moonAspects}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                        disabled={!advancedUnderstanding}
                                    />
                                    <label htmlFor="moonAspects" className={`text-sm ${!advancedUnderstanding ? 'text-gray-500' : ''}`}>Show When the Transiting Moon Aspects one of your natal planets.</label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="moonAspectHouseRulers"
                                        name="moonAspectHouseRulers"
                                        checked={formData.advancedOptions.moonAspectHouseRulers}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                        disabled={!advancedUnderstanding}
                                    />
                                    <label htmlFor="moonAspectHouseRulers" className={`text-sm ${!advancedUnderstanding ? 'text-gray-500' : ''}`}>Show Interpretations for Transiting Moon Activating your House Rulers.</label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="aspectHouseRulers"
                                        name="aspectHouseRulers"
                                        checked={formData.advancedOptions.aspectHouseRulers}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                        disabled={!advancedUnderstanding}
                                    />
                                    <label htmlFor="aspectHouseRulers" className={`text-sm ${!advancedUnderstanding ? 'text-gray-500' : ''}`}>Show Interpretations for Transiting Planets Activating your House Rulers (doesn't include the moon).</label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="outerPlanets"
                                        name="outerPlanets"
                                        checked={formData.advancedOptions.outerPlanets}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                        disabled={!advancedUnderstanding}
                                    />
                                    <label htmlFor="outerPlanets" className={`text-sm ${!advancedUnderstanding ? 'text-gray-500' : ''}`}>Show house changes and aspects for the outer planets.).</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Submit
                </button>
                <div className="text-sm text-gray-600">
                    Having problems? Try visiting our <a href="https://galacticplanner.com/pages/main-faqs" className="text-indigo-600 hover:text-indigo-800 hover:underline">FAQs Page</a> or Contact us at <a href="mailto:hello@galacticplanner.com" className="text-indigo-600 hover:text-indigo-800 hover:underline">hello@galacticplanner.com</a>
                </div>
            </form>):!formSubmitted && isConfirming?(
                <div className="text-gray-700">
                    <h2 className="text-2xl text-center font-bold">Please Confirm Your Details</h2>
                    <p><strong>Name:</strong> {formData.name}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Date of Birth:</strong> {formatDate(formData.dob)}</p>
                    <p><strong>Time of Birth:</strong> {formatTime(formData.tob)}</p>
                    <p><strong>Birthplace:</strong> {formData.birthplaceName}</p>
                    <div className="mt-4 flex space-x-4">
                        <button
                            onClick={handleFinalSubmit}
                            className="w-3/4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Confirm and Submit
                        </button>
                        <button
                            onClick={handleBack}
                            className="w-1/4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Go Back
                        </button>
                    </div>
                </div>

            ) : (
                <div className="text-center text-gray-700">
                    Building your personalized Galactic Planner. Please do not close this page until this message disappears.
                </div>
            )}
        </div>
    );
};

export default UserForm;
