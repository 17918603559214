import React from 'react';

const ThankYou = () => {
    return (
        <div className="max-w-md mx-auto p-4 space-y-4">
            <h1 className="text-xl text-indigo-900 font-bold">Thank You for Joining the Galactic Planner!</h1>
            <p>Your submission has been received. You will receive an email within the next 10 minutes which will include your Galactic Planner and other resources as an attachment.</p>
            <p>if you don't receive it or need any help please go to our <a href="https://galacticplanner.com/pages/main-faqs" className="text-indigo-600 hover:text-indigo-800 hover:underline">FAQs Page</a> or you can contact as at <a href="mailto:hello@galacticplanner.com" className="text-indigo-600 hover:text-indigo-800 hover:underline">hello@galacticplanner.com</a></p>
        </div>
    );
};

export default ThankYou;
