import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import UserForm from './components/UserForm';
import 'leaflet/dist/leaflet.css';
import ThankYou from "./components/ThankYou";
import Error from "./components/Error";

function App() {
  return (
      <div className="App">
          <Router>
              <Routes>
                  <Route path="/" element={<UserForm />} />
                  <Route path="/thank-you" element={<ThankYou />} />
                  <Route path="/error" element={<Error />} />

              </Routes>
          </Router>
      </div>
  );
}

export default App;
