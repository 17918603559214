import React, {useEffect, useState} from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconSize: [12.5, 21],
    iconAnchor: [6.25, 21],
    popupAnchor: [1, -34],
});

const MapUpdater = ({ position }) => {
    const map = useMap();
    if (position) {
        map.flyTo(position, 13);
    }
    return null;
};

const BirthplaceMap = ({ onLocationSelect, initialLocation, initialLocationName }) => {
    const [position, setPosition] = useState(initialLocation);
    const [searchQuery, setSearchQuery] = useState(initialLocationName);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (initialLocation) {
            setPosition(initialLocation);
        }
        if (initialLocationName) {
            setSearchQuery(initialLocationName);
        }
    }, [initialLocation, initialLocationName]);
    const MapEvents = () => {
        useMapEvents({
            click(e) {
                handleMapClick(e.latlng);
            },
        });
        return null;
    };

    const handleMapClick = async (latlng) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}`);
            const data = await response.json();
            if (data && data.display_name) {
                setPosition(latlng);
                setSearchQuery(data.display_name);
                onLocationSelect(latlng, data.display_name);
            } else {
                alert('Error in reverse geocoding. Please try again.');
            }
        } catch (error) {
            alert('Error in reverse geocoding. Please try again.');
        }
    };

    const searchLocation = async (query) => {
        try {
            const url = `https://nominatim.openstreetmap.org/search?format=json&q=${query}`;
            const response = await fetch(url);
            const data = await response.json();
            if (data && data.length > 0) {
                setSuggestions(data);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            setSuggestions([]);
        }
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 2) {
            searchLocation(query);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionSelect = (suggestion) => {
        const { lat, lon, display_name } = suggestion;
        const newCenter = [lat, lon];
        setPosition(newCenter);
        setSearchQuery(display_name);
        setSuggestions([]);
        onLocationSelect({ lat, lng: lon }, display_name);
    };

    return (
        <>
            <input
                type="text"
                value={searchQuery}
                placeholder="Search for a location"
                onChange={handleSearchChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {suggestions.length > 0 && (
                <ul className="border border-gray-300 rounded-md shadow-sm mt-1 max-h-60 overflow-auto">
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion.place_id}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleSuggestionSelect(suggestion)}
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            )}
            <div className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                <MapContainer center={[0, 0]} zoom={1} style={{ height: '400px', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    {position && <Marker position={position} icon={markerIcon}></Marker>}
                    <MapEvents />
                    <MapUpdater position={position} />
                </MapContainer>
            </div>
        </>
    );
};

export default BirthplaceMap;
